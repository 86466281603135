import { ArrowRight, InstagramLogo, TwitterLogo } from "phosphor-react";
import { useSubscribe } from "hooks";
import Link from "next/link";
import { Logo } from "./logo";
import { gaAnalytics } from "../lib/analytics";
import { APPLE_APP_STORE, INSTAGRAM, MAILTO, PRESS_KIT } from "lib/public-urls";
import Image from "next/image";

function SignupForm() {
  const { handleSubmit, status } = useSubscribe();

  return (
    <div>
      <p className="font-medium">
        Subscribe to our newsletter to get the latest news.
      </p>

      <form onSubmit={handleSubmit} className="mt-2">
        <div className="relative">
          <label htmlFor="mce-EMAIL" className="sr-only">
            Email Address
          </label>
          <input
            disabled={status.loading}
            required
            className="border-white rounded-md py-3 px-3.5 w-full bg-transparent placeholder:text-white pr-12 disabled:opacity-50 disabled:pointer-events-none"
            placeholder="Email address"
            type="email"
            name="email"
            id="mce-EMAIL"
          />
          <div className="absolute right-0 top-0 bottom-0 h-full flex items-center justify-center">
            <button
              disabled={status.loading}
              className="h-full px-4 flex items-center disabled:opacity-50 disabled:pointer-events-none"
              type="submit"
            >
              <span className="sr-only">Submit Button</span>
              <ArrowRight size={24} aria-hidden="true" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

const disclaimer = `Berry does not diagnose, treat, cure, or prevent diseases or act as a substitute for medical care. Information provided by Berry is not medical advice and is not intended to replace the advice of your doctor.`;
const captchaDisclaimer = (
  <span>
    This site is protected by reCAPTCHA and the Google{" "}
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
  </span>
);

const menuLinks = [
  {
    label: "For Patients",
    href: "/",
    newTab: false,
  },
  {
    label: "For Clinics",
    href: "/clinics",
    newTab: false,
  },
  // {
  //   label: "About",
  //   href: "/about",
  //   newTab: false,
  // },
  {
    label: "Articles",
    href: "/articles",
    newTab: false,
  },
  {
    label: "Glossary",
    href: "/glossary",
    newTab: false,
  },
  {
    label: "Press Kit",
    href: PRESS_KIT,
    newTab: true,
  },
];

const contactLinks: {
  label: string;
  href: string;
  icon?: React.ReactNode;
}[] = [
  {
    label: "hello@berryfertility.com",
    href: MAILTO,
  },
  {
    label: "berry.fertility",
    icon: <InstagramLogo size={24} aria-hidden />,
    href: INSTAGRAM,
  },
  // {
  //   label: "berryfertility",
  //   icon: <TwitterLogo size={24} aria-hidden />,
  //   href: TWITTER,
  // },
];

const gettingStartedLinks = [
  {
    label: "Get the app",
    href: APPLE_APP_STORE,
  },
  {
    label: "Take the quiz",
    href: "/quiz",
  },
];

const subFooterLinks = [
  {
    label: "Privacy policy",
    href: "/legal/privacy-policy",
  },
  {
    label: "Cookie policy",
    href: "/legal/cookie-policy",
  },
  {
    label: "Terms of use",
    href: "/legal/terms-of-use",
  },
];

export function Footer() {
  let currentYear = new Date().getFullYear();
  let copyright = `© ${currentYear} Early Works`;

  const { triggerTakeQuiz, triggerGetApp } = gaAnalytics();

  return (
    <footer className="bg-brand-gray-dark text-white pb-8 pt-20 overflow-hidden">
      <div className="container space-y-8 lg:space-y-24">
        <div className="space-y-6 lg:site-grid">
          <div className="col-span-4 space-y-12">
            <div className="w-36 h-auto">
              <Logo />
            </div>
            <SignupForm />
          </div>
          <div className="col-span-2 col-start-6 xl:col-start-7">
            <p className="text-sm font-medium uppercase tracking-wide text-white/50">
              Menu
            </p>
            <ul className="mt-2">
              {menuLinks.map((link) => (
                <li key={link.href}>
                  <Link href={link.href}>
                    <a
                      className="text-white font-medium text-lg hover:underline"
                      target={link.newTab ? "_blank" : "_self"}
                    >
                      {link.label}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-2">
            <p className="text-sm font-medium uppercase tracking-wide text-white/50">
              Get started
            </p>
            <ul className="mt-2">
              {gettingStartedLinks.map((link) => (
                <li key={link.href}>
                  <Link href={link.href}>
                    <a
                      className="text-white font-medium text-lg hover:underline"
                      onClick={() => {
                        if (link.label === "Get the app") {
                          triggerGetApp();
                        }
                        if (link.label === "Take the quiz") {
                          triggerTakeQuiz();
                        }
                      }}
                    >
                      {link.label}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-2">
            <p className="text-sm font-medium uppercase tracking-wide text-white/50">
              Contact
            </p>
            <ul className="mt-2 space-y-1">
              {contactLinks.map((link) => (
                <li key={link.href}>
                  <Link href={link.href}>
                    <a
                      className="text-white relative flex items-center"
                      target="_blank"
                    >
                      {link.icon && (
                        <div className="flex shrink-0 mr-2">{link.icon}</div>
                      )}
                      <span className="text-white font-medium text-lg hover:underline">
                        {link.label}
                      </span>
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row space-y-4 items-center lg:justify-between">
          <div>
            <div className="flex max-w-sm text-xs text-white/50 mb-2">
              {disclaimer}
            </div>
            <div className="flex max-w-sm text-xs text-white/50 mb-2">
              {captchaDisclaimer}
            </div>

            <ul className="flex items-center space-x-4">
              {subFooterLinks.map((link) => {
                return (
                  <li key={link.href}>
                    <Link href={link.href}>
                      <a className="text-white/50 font-medium text-lg hover:underline">
                        {link.label}
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex flex-col lg:!mt-auto">
            <div className="text-white/50 font-medium text-lg self-center lg:self-end ">
              {copyright}
            </div>
            <div className="flex flex-row w-full justify-center lg:justify-start">
              <Image
                src="/earlyworks.svg"
                alt="Early Works Logo"
                width={70}
                height={30}
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
